import Pristine from 'pristinejs';

(() => {
    const $validateForms = document.querySelectorAll('.validate-form');

    const initFormValidation = () => {
        if (!$validateForms) return;

        $validateForms.forEach($validateForm => {
            const pristine = new Pristine($validateForm);
        
            $validateForm.addEventListener('submit', e => {
                if (!pristine.validate()) {
                    e.preventDefault();
                }
            });
        });
    };

    initFormValidation();
})();
